import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

import Link from "./link"

const Container = styled.button`
  --text-color: ${props => props.theme.colorTurquoise};
  --decoration-color: currentColor;

  ${props => props.theme.ffSecondarySet()}

  padding: 2px 4px;
  position: relative;
  display: inline-block;
  clip-path: polygon(
    ${rem(10)} 0%,
    100% 0%,
    calc(100% - ${rem(10)}) 100%,
    0% 100%
  );
  font-size: ${rem(20)};
  letter-spacing: ${rem(1)};
  line-height: 1;
  white-space: nowrap;
  color: var(--text-color);
  border: solid var(--decoration-color);
  border-width: 1px 0;
  outline-offset: -1em;

  @media ${props => props.theme.smallDown} {
    font-size: ${rem(18)};
  }

  &:hover {
    > span::after {
      opacity: 0.5;
    }
  }

  &:active {
    > span::before {
      opacity: 0.4;
    }
  }

  &::before,
  &::after {
    content: "";
    width: 1px;
    position: absolute;
    top: -1px;
    bottom: -1px;
    background-color: var(--decoration-color);
    transform: rotate(12deg);
  }

  &::before {
    left: 5px;
  }

  &::after {
    width: 1px;
    right: 5px;
  }

  > span {
    height: ${rem(40)};
    padding: 0 1.4em;
    padding-top: 0.2em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: polygon(
      ${rem(8)} 0%,
      100% 0%,
      calc(100% - ${rem(8)}) 100%,
      0% 100%
    );

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &::before {
      background-color: var(--decoration-color);
      opacity: 0.2;
    }

    &::after {
      box-shadow: inset 0 0 0.8em 0.1em var(--decoration-color);
      opacity: 0.2;
      transition: opacity 0.2s ${props => props.theme.easingDefault};
    }
  }

  svg {
    width: 0.6em;
    height: 0.6em;
    margin-top: -0.1em;
    margin-right: 0.5em;
    filter: drop-shadow(0 0 0.6em var(--text-color));
  }

  br {
    display: none;
  }

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
      display: block;
    `}

  ${props =>
    props.color === `orange` &&
    css`
      --text-color: ${props => props.theme.colorOrange};
    `}

  ${props =>
    props.color === `yellow` &&
    css`
      --text-color: ${props => props.theme.colorYellow};
    `}

  ${props =>
    props.color === `black` &&
    css`
      --text-color: ${props => props.theme.colorYellow};
      --decoration-color: ${props => props.theme.colorBlack};

      > span {
        background-color: ${props => props.theme.colorBlack};
      }
    `}

  ${props =>
    props.color === `twitch` &&
    css`
      --text-color: ${props => props.theme.colorTwitch};
      --decoration-color: ${props => props.theme.colorGreyLightest};

      > span {
        background-color: ${props => props.theme.colorGreyLightest};
      }
    `}
`

const Button = ({ children, to, ...rest }) => {
  return (
    <Container as={to ? Link : undefined} to={to} {...rest}>
      <span>
        <span>{children}</span>
      </span>
    </Container>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
}

export default Button
