import type from 'sass-extract-loader?{"plugins": ["sass-extract-js"]}!../../assets/stylesheets/app/base/_type.scss'
import { css } from "styled-components"

type.ffPrimarySet = () => css`
  font-family: ${props => props.theme.ffPrimary};
  font-weight: ${props => props.theme.fwPrimaryNormal};
`

type.ffSecondarySet = () => css`
  font-family: ${props => props.theme.ffSecondary};
  font-weight: ${props => props.theme.fwSecondaryMedium};
  text-transform: uppercase;
  letter-spacing: -0.025em;

  strong {
    font-weight: ${props => props.theme.fwSecondaryMedium};
  }
`

export default type
