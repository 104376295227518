// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apexconnect-legacy-86-sd-76-ds-5-js": () => import("./../../../src/pages/apexconnect-legacy-86sd76ds5.js" /* webpackChunkName: "component---src-pages-apexconnect-legacy-86-sd-76-ds-5-js" */),
  "component---src-templates-basic-text-template-js": () => import("./../../../src/templates/basic-text-template.js" /* webpackChunkName: "component---src-templates-basic-text-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-character-template-js": () => import("./../../../src/templates/character-template.js" /* webpackChunkName: "component---src-templates-character-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-reclaimation-effort-template-js": () => import("./../../../src/templates/reclaimation-effort-template.js" /* webpackChunkName: "component---src-templates-reclaimation-effort-template-js" */)
}

