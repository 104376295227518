/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "element-closest"

import { responsiveVideos } from "./src/utils"

export function onRouteUpdate({ location, prevLocation }) {
  responsiveVideos()

  window.setTimeout(() => window.scrollTo(0, 0), 500)

  if (location && prevLocation) {
    const event = document.createEvent(`Event`)
    event.initEvent(`onRouteChange`, true, true)
    window.dispatchEvent(event)

    if (window.trackingUtil && window.trackingUtil.trackingAccepted()) {
      setTimeout(() => {
        window.trackingUtil.registerGTMdata({ event: `gatsby-page-view` })
      }, 50)
    }
  }
}

export function onClientEntry() {
  document.addEventListener(`click`, e => {
    if (!window.trackingUtil || !window.trackingUtil.trackingAccepted()) {
      return null
    }

    const trigger = e.target.closest(`[data-track-click]`)
    if (trigger) {
      const {
        trackClickGaCategory,
        trackClickGaAction,
        trackClickGaLabel,
      } = trigger.dataset

      window.trackingUtil.registerGTMdata({
        category: trackClickGaCategory,
        action: trackClickGaAction,
        label: trackClickGaLabel,
      })

      if (window.ga) {
        window.ga(
          `ga_secondextinction.send`,
          `event`,
          trackClickGaCategory,
          trackClickGaAction,
          trackClickGaLabel
        )
      }
    }

    return true
  })
}

export function shouldUpdateScroll() {
  return false
}
