import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

const contentVariants = {
  initial: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
  },
  active: {
    opacity: 1,
    transition: {
      duration: 0.75,
      delay: 1.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0,
      delay: 0.75,
    },
  },
}

const Overlay = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: #000;
  z-index: 10000;
`

const OverlayOuter = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 0;
  z-index: 15000;
`

const overlayVariants = {
  initial: {
    height: `0vh`,
    transition: {
      delay: 0,
      duration: 0,
    },
  },
  active: {
    height: `0vh`,
    transition: {
      duration: 0,
      delay: 0,
    },
  },
  exit: {
    height: `100vh`,
    opacity: 0,
    transition: {
      height: {
        duration: 0.5,
        delay: 0,
      },
      opacity: {
        duration: 0.5,
        delay: 0.75,
      },
    },
  },
}

const LayoutTransition = ({ path, children }) => {
  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    if (document.getElementById(`warsupport-form`) || path !== `/warsupport/`) {
      if (window.recaptcha) {
        if (document.getElementById(`google-recaptcha-v3`)) {
          document
            .getElementById(`google-recaptcha-v3`)
            .parentElement.removeChild(
              document.getElementById(`google-recaptcha-v3`)
            )
        }

        if (document.querySelector(`.grecaptcha-badge`)) {
          document
            .querySelector(`.grecaptcha-badge`)
            .parentElement.removeChild(
              document.querySelector(`.grecaptcha-badge`)
            )
        }
      }
    }
  }

  return (
    <AnimatePresence initial={false}>
      <OverlayOuter key={path + path}>
        <Overlay
          variants={overlayVariants}
          initial={`initial`}
          animate={`active`}
          exit={`exit`}
        ></Overlay>
      </OverlayOuter>

      <motion.div
        variants={contentVariants}
        initial={`initial`}
        animate={`active`}
        exit={`exit`}
        key={path}
      >
        <React.Fragment>{children}</React.Fragment>
      </motion.div>
    </AnimatePresence>
  )
}

export default LayoutTransition

LayoutTransition.propTypes = {
  path: PropTypes.string,
  children: PropTypes.any,
}
