import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import TrackingUtil from "@oddcamp/tracking-util"

import Button from "./button"

const Container = styled.div`
  width: 100%;
  position: fixed;
  z-index: ${props => props.theme.ziCookiesConsent};
  bottom: 0;
  right: 0;
  padding: ${rem(30)};
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  @media ${props => props.theme.xsmallDown} {
    padding: ${rem(10)};
  }
`

const Box = styled.div`
  width: 100%;
  max-width: 20em;
  padding: 1.4em;
  padding-top: 2.4em;
  pointer-events: auto;
  color: ${props => props.theme.colorWhite};
  background-color: ${props => props.theme.colorGreyDarkest};
  clip-path: polygon(
    calc(50% - 0.6em) 1em,
    calc(50% + 0.6em) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 1em
  );
`

const Text = styled.p`
  margin-bottom: 1em;
`

const Cta = styled.div`
  display: flex;
`

const CookiesConsent = ({
  disclaimer,
  ctaAccept,
  ctaDeny,
  children,
  ...props
}) => {
  const [reacted, setReacted] = useState(false)

  if (typeof window === `undefined` || reacted) {
    return null
  }

  let tu = window.trackingUtil
  if (!tu) {
    tu = new TrackingUtil({
      services: {
        gtm: {
          id: `GTM-TSH3GLR`,
        },
      },
    })
  }

  if (tu.userReacted()) {
    return null
  }

  const acceptTracking = value => {
    tu.setTrackingAccepted(true, {
      defaultGTMdataLayer: [
        { pageType: window.pageType },
        { userStatus: window.localStorage.getItem(`apex-ref-status`) },
        { referralData: window.localStorage.getItem(`apex-ref-data`) },
        { event: `gatsby-page-view` },
      ],
    })

    setReacted(true)
  }

  return (
    <Container {...props}>
      <Box>
        <Text
          dangerouslySetInnerHTML={{
            __html:
              disclaimer ||
              children ||
              `By accepting our use of cookies you'll improve your experience of this website.`,
          }}
        />

        <Cta>
          <Button type="button" onClick={() => acceptTracking(true)}>
            {ctaAccept || `Accept`}
          </Button>

          <Button
            type="button"
            color="yellow"
            onClick={() => acceptTracking(false)}
          >
            {ctaDeny || `Deny`}
          </Button>
        </Cta>
      </Box>
    </Container>
  )
}

CookiesConsent.propTypes = {
  children: PropTypes.node,
  disclaimer: PropTypes.string,
  ctaAccept: PropTypes.string,
  ctaDeny: PropTypes.string,
}

export default CookiesConsent
