import React from "react"
import PropTypes from "prop-types"

const StoreContext = React.createContext()
const StoreConsumer = StoreContext.Consumer

class StoreProvider extends React.Component {
  state = {
    headerInverted: false,
    showBanner: true,
    status: `anonymous`,
  }

  render() {
    const { headerInverted, showBanner, status } = this.state

    const { setHeaderInverted, setShowBanner, setStatus } = this

    return (
      <StoreContext.Provider
        value={{
          headerInverted,
          showBanner,
          status,
          setHeaderInverted,
          setShowBanner,
          setStatus,
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    )
  }

  setHeaderInverted = val => {
    this.setState(prevState => ({ ...prevState, headerInverted: val }))
  }
  setShowBanner = val => {
    this.setState(prevState => ({ ...prevState, showBanner: val }))
  }
  setStatus = val => {
    this.setState(prevState => ({ ...prevState, status: val }))
  }
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { StoreContext, StoreProvider, StoreConsumer }
