import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import Link from "./link"
import { ReactComponent as SvgSystemicReaction } from "../assets/images/logo-systemic-reaction-white.svg"
import imgApex from "../assets/images/logo-apex-white.png"

import pegi from "../assets/images/footer/ratings/pegi.png"
import mmature from "../assets/images/footer/ratings/mmature.png"

const Container = styled.footer`
  padding: ${rem(40)} ${rem(40)} ${rem(160)};
  background-color: ${props => props.theme.colorBlack};

  @media ${props => props.theme.mediumDown} {
    padding-bottom: ${rem(80)};
  }

  @media ${props => props.theme.xsmallDown} {
    padding-left: 0;
    padding-right: 0;
  }
`

const Inner = styled.div`
  ${props => props.theme.gridContainer()}
`

const Upper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${props => props.theme.mediumDown} {
    flex-direction: column;
  }
`

const UpperNav = styled.nav`
  ${props => props.theme.ffSecondarySet()}

  margin-left: ${rem(40)};
  order: 1;
  display: flex;
  flex-wrap: wrap;
  font-size: ${rem(20)};

  @media ${props => props.theme.mediumDown} {
    display: block;
    width: 100%;
  }

  a {
    margin: ${rem(3)} 0;

    &:not(:last-child) {
      @media ${props => props.theme.mediumUp} {
        margin-right: ${rem(20)};
      }
    }

    &:hover {
      color: ${props => props.theme.colorYellow};
    }

    &:active {
      color: ${props => props.theme.colorOrange};
    }

    @media ${props => props.theme.mediumDown} {
      display: inline-block;
      width: 100%;
    }
  }

  @media ${props => props.theme.largeDown} {
    margin-left: 0;
    margin-top: ${rem(30)};
  }

  @media ${props => props.theme.mediumDown} {
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
`

const UpperLogos = styled.div`
  display: flex;
  flex-wrap: wrap;

  svg,
  img {
    height: ${rem(80)};
    width: auto;
  }

  > * {
    margin: ${rem(10)} 0;

    &:not(:last-child) {
      margin-right: ${rem(20)};
    }
  }
`

const Lower = styled.div`
  margin-top: ${rem(30)};
  padding-top: ${rem(40)};
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colorGreyDarkest};

  @media ${props => props.theme.largeDown} {
    flex-direction: column;
    margin-top: ${rem(20)};
    padding-top: ${rem(10)};
  }

  @media ${props => props.theme.mediumDown} {
    text-align: center;
  }
`

const LowerInfo = styled.div`
  margin-right: 15%;
  font-size: ${rem(12)};

  > .-copyright {
  }

  > .-disclaimer {
    opacity: 0.75;

    p {
      margin-top: ${rem(10)};
    }
  }

  > .-links {
    ${props => props.theme.ffSecondarySet()}

    margin-top: ${rem(10)};
    display: flex;
    flex-wrap: wrap;
    font-size: ${rem(16)};

    li {
      margin-top: ${rem(10)};

      &:not(:last-child) {
        margin-right: ${rem(20)};
      }
    }

    a {
      &:hover {
        color: ${props => props.theme.colorYellow};
      }

      &:active {
        color: ${props => props.theme.colorOrange};
      }
    }

    @media ${props => props.theme.largeDown} {
      justify-content: center;
      margin-top: ${rem(20)};
      margin-bottom: ${rem(30)};
    }
  }

  @media ${props => props.theme.largeDown} {
    margin-right: 0;
  }
`

const LowerEmblems = styled.div`
  width: 100%;
  max-width: ${rem(135)};
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    max-width: ${rem(100)};
    width: 100%;
    margin-left: ${rem(12)};
  }

  img {
    max-width: 100%;
    width: 100%;
  }

  @media ${props => props.theme.largeDown} {
    margin: 0 auto;
  }
`

const Footer = () => {
  const {
    headerOptions: {
      options: { menu },
    },
    footerOptions: {
      options: { copyrightText, ageRatingInfo, links },
    },
  } = useStaticQuery(graphql`
    query {
      ...HeaderOptionsFragment
      ...FooterOptionsFragment
    }
  `)

  return (
    <Container>
      <Inner>
        <Upper>
          {menu && menu.length > 0 && (
            <UpperNav>
              <Link
                to="/"
                data-track-click
                data-track-click-ga-category="Footer"
                data-track-click-ga-action="Nav Click"
                data-track-click-ga-label="Home"
              >
                Home
              </Link>
              {menu.map((item, i) => (
                <Link
                  key={i}
                  to={item.url}
                  className={item.cssClassNames}
                  activeClassName="--active"
                  data-track-click
                  data-track-click-ga-category="Footer"
                  data-track-click-ga-action="Nav Click"
                  data-track-click-ga-label={item.title}
                >
                  {item.title}
                </Link>
              ))}
            </UpperNav>
          )}

          <UpperLogos>
            <Link
              to="https://avalanchestudios.com/divisions/systemic-reaction"
              data-track-click
              data-track-click-ga-category="Footer"
              data-track-click-ga-action="Logo Click"
              data-track-click-ga-label="Systemic Reaction"
            >
              <SvgSystemicReaction
                aria-label="Systemic Reaction"
                title="Systemic Reaction"
              />
            </Link>

            <img src={imgApex} alt="Apex Engine" />
          </UpperLogos>
        </Upper>

        <Lower>
          <LowerInfo>
            {copyrightText && (
              <p
                className="-copyright"
                dangerouslySetInnerHTML={{ __html: copyrightText }}
              />
            )}

            {ageRatingInfo && (
              <div
                className="-disclaimer"
                dangerouslySetInnerHTML={{ __html: ageRatingInfo }}
              />
            )}

            {links && links.length > 0 && (
              <ul className="-links">
                {links.map((item, i) => (
                  <li key={i}>
                    <Link
                      to={item.url}
                      activeClassName="--active"
                      data-track-click
                      data-track-click-ga-category="Footer"
                      data-track-click-ga-action="Secondary Nav Click"
                      data-track-click-ga-label={item.title}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </LowerInfo>

          <LowerEmblems>
            <div>
              <img src={pegi} alt="" />
            </div>
            <div>
              <img src={mmature} alt="" />
            </div>
          </LowerEmblems>
        </Lower>
      </Inner>
    </Container>
  )
}

export default Footer
