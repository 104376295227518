import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import initSmartOutline from "@oddcamp/js-utils/src/smart-outline"
import Helmet from "react-helmet"

// import "../config/yup"
// import "../assets/stylesheets/fonts/woff.scss"
import "../assets/stylesheets/app/app.scss"

import MetaWp from "../components/meta-wp"
import Footer from "../components/footer"
import CookiesConsent from "../components/cookies-consent"
// import SiteBanner from "../components/site-banner"
import { hasWindow } from "../utils"
import { StoreProvider } from "../store"
import theme from "../theme"

import LayoutTransition from "../components/layout-transition"

if (typeof document !== `undefined`) {
  initSmartOutline([
    `input:focus`,
    `button:focus`,
    `textarea:focus`,
    `select:focus`,
    `.carousel__slider:focus`,
  ])
}

const Layout = ({ path, children }) => {
  if (hasWindow && !localStorage.getItem(`apex-ref-status`)) {
    localStorage.setItem(`apex-ref-status`, `anonymous`)
  }

  return (
    <ThemeProvider theme={theme}>
      <StoreProvider>
        <React.Fragment>
          <MetaWp />

          <Helmet>
            <link
              href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Teko:wght@500;700&display=swap"
              rel="stylesheet"
            />
          </Helmet>

          <LayoutTransition path={path}>
            <main style={{ position: `relative`, minHeight: `100vh` }}>
              {children}
            </main>
          </LayoutTransition>

          {/* <SiteBanner /> */}

          <Footer />

          <CookiesConsent />
        </React.Fragment>
      </StoreProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
}

export default Layout
