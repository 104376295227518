const hasWindow = typeof window !== `undefined`

function findGetParameter(parameterName) {
  if (!hasWindow) {
    return null
  }

  let result = null
  let tmp = []

  window.location.search
    .substr(1)
    .split(`&`)
    .forEach(function (item) {
      tmp = item.split(`=`)
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
    })
  return result
}

function responsiveVideos() {
  const iframes = document.querySelectorAll([
    `iframe[src*="youtube.com"]`,
    `iframe[src*="vimeo.com"]`,
  ])

  if (iframes.length) {
    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i]
      const width = iframe.getAttribute(`width`)
      const height = iframe.getAttribute(`height`)
      const aspectRatio = height / width
      const parentDiv = iframe.parentNode
      const div = document.createElement(`div`)

      div.style.position = `relative`
      div.style.paddingBottom = aspectRatio * 100 + `%`
      parentDiv.insertBefore(div, iframe)
      iframe.remove()
      div.appendChild(iframe)
      iframe.removeAttribute(`height`)
      iframe.removeAttribute(`width`)
      iframe.style.width = `100%`
      iframe.style.height = `100%`
      iframe.style.position = `absolute`
      iframe.style.top = `0`
      iframe.style.left = `0`
    }
  }
}

export { findGetParameter, hasWindow, responsiveVideos }
