module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Second Extinction","short_name":"Second Ext","description":"Second Extinction is an intense 3 player cooperative shooter, where your goal is to wipe out the mutated dinosaurs that have taken over the planet.","theme_color":"#fabd04","background_color":"#0a0a0a","icon":"static/meta-images/icon-bg-transparent.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f0567063382f47c4a49f8fb7fb2178d5@o493417.ingest.sentry.io/5562368","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
